import RoundedButton from "../../../components/RoundedButton";
import {Link} from "react-router-dom";
import {Stack} from "react-bootstrap";

const Ready = () => {
    return (
        <Stack className="justify-content-center text-center" gap={3}>
            <h3>Thank you!</h3>
            <p>
                Once your results are ready we will notify you via email
            </p>
            <Link to="/account"><RoundedButton>Finish</RoundedButton></Link>
        </Stack>
    )
}

export default Ready;