import {useEffect} from "react";
import {useLocation} from "react-router-dom";

const Title = ({title, children}) => {
    const location = useLocation();

    useEffect(() => {
        document.title = title ? "S'Wipe App | " + title : "S'Wipe App";
    }, [location, title]);

    return children;
}

export default Title;