import {Image, ListGroup, Stack} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import React from "react";
import swipeAndTube from "../../../assets/images/swipe-and-tube-256.png"

const Intro = ({next}) => {
    return (
        <Stack className="justify-content-center" gap={1}>
            <h3 className="text-center flex-grow-1">Collect Your Samples</h3>
            {/*<p className="text-center">*/}
            {/*    In order to complete the test, you will need:*/}
            {/*</p>*/}

            <div className="flex-grow-1">
                <ListGroup>
                    <ListGroup.Item>
                        <div className="text-center">
                            <Image src={swipeAndTube} className="bg-primary-dark bg-gradient border border-primary"
                                   width="192px" alt="Swipe and Tube" thumbnail/>
                        </div>
                    </ListGroup.Item>
                    <ListGroup.Item className="small text-start" variant="light">
                        <ol className="flex-grow-1">
                            <li>Use the provided wipe, just like toilet paper, after a bowel movement</li>
                            <li>Place the used wipe into the collection tube with the preservation fluid</li>
                            <li>Securely close the tube to avoid any leaks during shipping</li>
                        </ol>
                        <div className="text-end">
                            <a href="https://s-wipe.shop/instructions" target="_blank" rel="noreferrer">Read more...</a>
                        </div>
                    </ListGroup.Item>
                </ListGroup>
            </div>
            {/*<p className="fw-bold text-center flex-grow-1 small">*/}
            {/*    If you do not have the sampling kit, order it at <a href="https://s-wipe.shop" target="_blank"*/}
            {/*                                                        rel="noreferrer">https://s-wipe.shop</a>*/}
            {/*</p>*/}
            <Stack direction="horizontal" gap={3}>
                <Button className="ms-auto w-50" onClick={() => next()}>Next</Button>
            </Stack>
        </Stack>
    )
}

export default Intro;