export class NotFoundError extends Error {
    constructor(message) {
        super(message);
        this.name = 'NotFoundError';
    }
}

export class DuplicationError extends Error {
    constructor(message) {
        super(message);
        this.name = 'DuplicationError';
    }
}