import {Stack, Table} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import React from "react";
import type1 from "../../../assets/images/bristol_stool_chart/type1.png";
import type2 from "../../../assets/images/bristol_stool_chart/type2.png";
import type3 from "../../../assets/images/bristol_stool_chart/type3.png";
import type4 from "../../../assets/images/bristol_stool_chart/type4.png";
import type5 from "../../../assets/images/bristol_stool_chart/type5.png";
import type6 from "../../../assets/images/bristol_stool_chart/type6.png";
import type7 from "../../../assets/images/bristol_stool_chart/type7.png";
import {useUserAuth} from "../../../contexts/UserAuthContext";

const BristolChart = ({next, barcode}) => {

    const stoolTypes = {
        'Type 1': {'image': type1, 'description': 'Separate hard lumps, like nuts (hard to pass)'},
        'Type 2': {'image': type2, 'description': 'Sausage-shaped but lumpy'},
        'Type 3': {'image': type3, 'description': 'Like a sausage but with cracks on its surface'},
        'Type 4': {'image': type4, 'description': 'Like a sausage or snake, smooth and soft'},
        'Type 5': {'image': type5, 'description': 'Soft blobs with clear-cut edges (passed easily)'},
        'Type 6': {'image': type6, 'description': 'Fluffy pieces with ragged edges, a mushy stool'},
        'Type 7': {'image': type7, 'description': 'Watery, no solid pieces. ENTIRELY LIQUID'}
    }

    const {userData, updateScanData} = useUserAuth();
    const [selectedStoolType, setSelectedStoolType] = React.useState(userData.bristolStoolType);

    const handleClick = () => {
        if (updateScanData(barcode, "bristolStoolType", selectedStoolType)) {
            next();
        }
    }

    return (
        <>
            <Stack className="overflow-y-auto" gap={1}>
                <h3 className="flex-grow-1">How your stool looks like?</h3>
                <div className="flex-grow-1">
                    <Table borderless hover className="small">
                        <tbody>
                        {
                            Object.keys(stoolTypes).map((type) => (
                                <tr key={type}
                                    className={"align-middle " + (selectedStoolType === type ? "table-primary" : "")}
                                    onClick={() => setSelectedStoolType(type)}>
                                    <th style={{width: '20%'}}>{type}</th>
                                    <td style={{width: '30%'}}><img src={stoolTypes[type].image} style={{width: '100%'}}
                                                                    alt={type}/></td>
                                    <td className="small">{stoolTypes[type].description}</td>
                                </tr>
                            ))
                        }
                        </tbody>
                    </Table>
                </div>
            </Stack>
            <Stack direction="horizontal" gap={3}>
                <Button className="ms-auto w-50" onClick={handleClick}
                        disabled={selectedStoolType === undefined || selectedStoolType === null}>
                    Next
                </Button>
            </Stack>
        </>
    )
}

export default BristolChart;