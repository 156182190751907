import RoundedButton from "../../../components/RoundedButton";
import {Link, useNavigate} from "react-router-dom";
import {useUserAuth} from "../../../contexts/UserAuthContext";
import {useEffect, useState} from "react";

const Ready = () => {
    const {checkFields} = useUserAuth();
    const [check, setCheck] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        checkFields()
            .then(success => setCheck(success))
    }, []);

    return (
        <>
            <h3 className="flex-grow-1">Ready to begin testing?</h3>
            <p>
                Please make sure to read the instruction for sample collection and that you are ready to defecate
            </p>
            <RoundedButton disabled={!check} onClick={() => navigate("/account/test")}>
                Begin Testing
            </RoundedButton>
            <Link to="/account"><RoundedButton variant="secondary">Test Later</RoundedButton></Link>
            <span className="flex-grow-1"/>
        </>
    )
}

export default Ready;