import {Button, Stack, ToggleButton} from 'react-bootstrap';
import 'react-multi-carousel/lib/styles.css';
import {useUserAuth} from "../../../contexts/UserAuthContext";
import {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBurger, faDrumstickBite, faFish, faPlantWilt, faScaleBalanced, faUtensils, faV, faWheatAwn} from "@fortawesome/free-solid-svg-icons";

const Diet = ({prev, next}) => {
    const {updateUserData, userData} = useUserAuth();
    const diets = {
        "Balanced Diet": [faScaleBalanced, "black"],
        "Carnivore": [faDrumstickBite, "red"],
        "Gluten Free": [faWheatAwn, "brown"],
        "Low Carbs": [faUtensils, "blue"],
        "Vegetarian": [faV, "green"],
        "Pescatarian": [faFish, "blue"],
        "Standard American Diet": [faBurger, "red"],
        "Vegan": [faPlantWilt, "green"]
    };
    const [selectedDiets, setSelectedDiets] = useState(userData.diets || []);
    const dietButtons = Object.keys(diets).map((diet) =>
        <ToggleButton id={diet.replace(' ', '-').toLowerCase()}
                      key={diet.replace(' ', '-').toLowerCase()}
                      value={diet} type="checkbox" className="d-flex align-items-center rounded-5" variant="outline-dark"
                      checked={selectedDiets !== undefined && selectedDiets.indexOf(diet) > -1}
                      onChange={(e) => {
                          const selected = selectedDiets !== undefined ? selectedDiets.slice() : [];
                          const index = selected.indexOf(diet);
                          if (index > -1) {
                              selected.splice(index, 1);
                          } else {
                              selected.push(diet);
                          }
                          setSelectedDiets(selected);
                      }}>
            <FontAwesomeIcon icon={diets[diet][0]} color={selectedDiets.indexOf(diet) > -1 ? "white" : diets[diet][1]}/>&nbsp;<span className="flex-grow-1">{diet}</span>
        </ToggleButton>
    )

    function handleClick() {
        updateUserData("diets", selectedDiets);
        next();
    }

    return (
        <>
            <Stack className="overflow-y-auto" gap={1} style={{height: '100%'}}>
                <h3 className="flex-grow-1">What are your eating habits?</h3>
                <p>Please select all diet options that apply.</p>
                {dietButtons}
                <span className="flex-grow-1"/>
            </Stack>
            <Stack direction="horizontal" gap={3}>
                <Button className="w-50" variant="secondary" onClick={prev}>Previous</Button>
                <Button className="w-50" onClick={handleClick} disabled={selectedDiets.length === 0}>Next</Button>
            </Stack>
        </>
    )
}

export default Diet;