import {Form, Stack, ToggleButton} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {useUserAuth} from "../../../contexts/UserAuthContext";
import {useState} from "react";

const GutIssues = ({prev, next}) => {
    const {updateUserData, userData} = useUserAuth();
    const [gutIssues, setGutIssues] = useState(userData.gutIssues);
    const [hasGutIssues, setHasGutIssues] = useState(gutIssues !== undefined && gutIssues !== null);

    function handleClick() {
        if (gutIssues && gutIssues.length > 0) {
            updateUserData("gutIssues", gutIssues);
        }
        next();
    }

    return (
        <>
            <Stack className="justify-content-center" gap={3}>
                <h3>Do you have any gut issues?</h3>
                {/*<p>Please specify any gut issues that you currently have (max 200 characters).</p>*/}
                {/*<p>Skip if you prefer not to answer this question.</p>*/}

                <ToggleButton id="no" value="no" className="rounded-5" type="checkbox" variant="outline-dark"
                              checked={!hasGutIssues}
                              onChange={() => {
                                  setGutIssues(null);
                                  setHasGutIssues(false);
                              }}>
                    No
                </ToggleButton>
                <ToggleButton id="yes" value="yes" className="rounded-5" type="checkbox" variant="outline-dark"
                              checked={hasGutIssues}
                              onChange={() => {
                                  // setGutIssues(document.getElementById('textarea').value);
                                  setHasGutIssues(true);
                              }}>

                    Yes
                </ToggleButton>
                <Form.Group className="flex-grow-1 d-flex flex-column">
                    <Form.Control id="textarea" as="textarea" className="flex-grow-1" maxLength={200}
                                  placeholder="Please specify any gut issues that you currently have (max 200 characters)"
                                  onChange={(e) => {
                                      setGutIssues(e.currentTarget.value);
                                      setHasGutIssues(true);
                                  }}/>
                    <p className="small">{gutIssues === undefined || gutIssues === null ? 0 : gutIssues.length}/200</p>
                </Form.Group>
            </Stack>
            <Stack direction="horizontal" gap={3}>
                <Button className="w-50" variant="secondary" onClick={prev}>Previous</Button>
                <Button className="w-50" onClick={handleClick}
                        disabled={hasGutIssues && (gutIssues === undefined || gutIssues === null || gutIssues.length === 0)}>
                    Next
                </Button>
            </Stack>
        </>
    )
}

export default GutIssues;