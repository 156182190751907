import {useState, useEffect} from "react";
import {Link, useNavigate, useSearchParams} from 'react-router-dom';
import {Form, InputGroup, Stack} from "react-bootstrap";
import {Envelope, Lock, Person} from "react-bootstrap-icons";
import ReCAPTCHA from "react-google-recaptcha";
import RoundedButton from "../components/RoundedButton";
import {useUserAuth} from "../contexts/UserAuthContext";
import {mapAuthCodeToMessage} from "../firebase";
import Column from "../components/Column";
import {captureException} from "@sentry/react";
import * as Sentry from "@sentry/react";


const Login = () => {

    const [params] = useSearchParams();
    const email = params.get('email');
    const reference = params.get('ref');

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: email ?? '',
        password: '',
        repeatPassword: '',
        consent: false
    });

    const [captcha, setCaptcha] = useState(false);
    const [validated, setValidated] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const {signUp, user} = useUserAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (user) {
            navigate("/account");
        }
    }, []);

    const handleChange = (e) => {
        const {name, value} = e.currentTarget;
        setFormData({...formData, [name]: value});
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const form = e.currentTarget;
        if (formData.password !== formData.repeatPassword) {
            setValidated(false);
            setErrorMessage("Passwords don't match");
            return;
        }

        if (form.checkValidity() && formData.consent && formData.password === formData.repeatPassword) {
            signUp(formData.email, formData.firstName, formData.lastName, formData.password, reference)
                .then(() => navigate('/account'))
                .catch((error) => {
                    console.log(error.code, error.message);
                    const message = mapAuthCodeToMessage(error.code);
                    if (message !== undefined) {
                        setErrorMessage(message);
                        setValidated(false);
                    } else {
                        captureException(error);
                        navigate("/error", {
                            state: {
                                error: error,
                                code: error.code,
                                message: error.message
                            }
                        });
                    }
                });
        } else {
            setValidated(false);
        }
        // setValidated(true);
    };

    const validate = () => {
        const keys = Object.keys(formData);
        for (let i = 0; i < keys.length; i++) {
            const value = formData[keys[i]];
            // const value = formData[key];
            if (!value) return false;
            if (value instanceof String && value.length === 0) return false;
            if (value instanceof Boolean && !value) return false;
        }
        return true;
    }

    return (
        <Sentry.ErrorBoundary fallback={<p>Something went wrong.</p>}>
            <Column>
                <Form validated={validated} onSubmit={handleSubmit}>
                    <Stack className="d-flex justify-content-center text-center vh-custom" gap={3}>
                        <div className="h2 fw-bold">Sign Up</div>
                        <h6 className="error text-danger">{errorMessage}</h6>
                        <Stack direction="horizontal" gap={3}>
                            <InputGroup hasValidation>
                                <InputGroup.Text><Person/></InputGroup.Text>
                                <Form.Control type="text" name="firstName" value={formData.firstName}
                                              onChange={handleChange} placeholder="First name" required/>
                            </InputGroup>
                            <InputGroup hasValidation>
                                <Form.Control type="text" name="lastName" value={formData.lastName}
                                              onChange={handleChange} placeholder="Last name" required/>
                            </InputGroup>
                        </Stack>
                        <InputGroup hasValidation>
                            <InputGroup.Text><Envelope/></InputGroup.Text>
                            <Form.Control type="email" name="email" value={formData.email} onChange={handleChange}
                                          placeholder="Enter your email" disabled={email} required/>
                        </InputGroup>
                        <InputGroup hasValidation>
                            <InputGroup.Text><Lock/></InputGroup.Text>
                            <Form.Control type="password" name="password" value={formData.password}
                                          onChange={handleChange} placeholder="Enter your password" required/>
                        </InputGroup>
                        <InputGroup hasValidation>
                            <InputGroup.Text><Lock/></InputGroup.Text>
                            <Form.Control type="password" name="repeatPassword" value={formData.repeatPassword}
                                          onChange={handleChange} placeholder="Enter your password" required
                                          isInvalid={formData.password !== formData.repeatPassword}/>
                        </InputGroup>
                        <InputGroup hasValidation>
                            <Form.Check.Label>
                                <Form.Check.Input type="checkbox" name="consent" className="border-primary me-2"
                                                  checked={formData.consent} required
                                                  onChange={(e) =>
                                                      setFormData({...formData, 'consent': e.currentTarget.checked})}
                                />

                                <span>I agree to Arome's&nbsp;</span>
                                <Link to="https://s-wipe.shop/terms-and-conditions" target="_blank">Terms of
                                    Service</Link>
                                <span>&nbsp;and&nbsp;</span>
                                <Link to="https://s-wipe.shop/privacy-policy" target="_blank">Privacy Policy</Link>
                            </Form.Check.Label>
                            {/*<Form.Check type="switch" name="consent" value={formData.consent} onChange={handleChange}*/}
                            {/*            className="form-control-lg" required label={*/}
                            {/*    <div>*/}
                            {/*        <span>I agree to Arome's&nbsp;</span>*/}
                            {/*        <Link to="https://s-wipe.shop/terms-and-conditions" target="_blank">Terms of*/}
                            {/*            Service</Link>*/}
                            {/*        <span>&nbsp;and&nbsp;</span>*/}
                            {/*        <Link to="https://s-wipe.shop/privacy-policy" target="_blank">Privacy Policy</Link>*/}
                            {/*    </div>*/}
                            {/*}/>*/}
                        </InputGroup>
                        <div className="d-flex justify-content-center">
                            <ReCAPTCHA sitekey={process.env["REACT_APP_SITE_KEY"]}
                                       onChange={(x) => setCaptcha(x != null)}/>
                        </div>
                        <div></div>
                        <RoundedButton variant="primary" type="submit" disabled={!captcha || !validate()}>
                            Sign Up
                        </RoundedButton>
                        <div>
                            Already have an account? <Link to="/login">Sign in</Link>
                        </div>
                    </Stack>
                </Form>
            </Column>
        </Sentry.ErrorBoundary>
    )
}

export default Login;