import React from "react";
import Button from 'react-bootstrap/Button';

function RoundedButton({className, children, ...props}) {
    return (
        <Button className={className + " rounded-5 w-100"} {...props}>
            {children}
        </Button>
    )
}

export default RoundedButton;