import {Stack, ToggleButton} from 'react-bootstrap';
import 'react-multi-carousel/lib/styles.css';
import Button from "react-bootstrap/Button";
import {useUserAuth} from "../../../contexts/UserAuthContext";
import {useState} from "react";

const Supplements = ({prev, next, barcode}) => {
    const {updateScanData, getLastTestExcept} = useUserAuth();
    const previousTest = getLastTestExcept(barcode);
    const supplements = ["AXOS Supplement", "GOS Supplement", "Inulin Supplement", "Maise/Corn Supplement",
        "Polydextrose Supplement", "Wheat Supplement", "Whole Grain Supplement", "XOS Supplement"];
    const [selectedSupplements, setSelectedSupplements] = useState(previousTest?.supplements ?? []);
    const supplementButtons = supplements.map((supplement) =>
        <ToggleButton id={supplement.replace(' ', '-').toLowerCase()}
                      key={supplement.replace(' ', '-').toLowerCase()}
                      value={supplement} type="checkbox" className="rounded-5" variant="outline-dark"
                      checked={selectedSupplements !== undefined && selectedSupplements.indexOf(supplement) > -1}
                      onChange={(e) => {
                          const selected = selectedSupplements !== undefined ? selectedSupplements.slice() : [];
                          const index = selected.indexOf(supplement);
                          if (index > -1) {
                              selected.splice(index, 1);
                          } else {
                              selected.push(supplement);
                          }
                          setSelectedSupplements(selected);
                      }}>
            {supplement}
        </ToggleButton>
    )

    function handleClick() {
        updateScanData(barcode, "supplements", selectedSupplements)
            .then(() => next());
    }

    return (
        <>
            <Stack className="overflow-y-auto" gap={1}>
                <h3 className="flex-grow-1">What fiber supplements do you take?</h3>
                <p>Skip if you don't take any supplements.</p>
                <ToggleButton id="none" value="None" type="checkbox" className="rounded-5" variant="outline-dark"
                              checked={selectedSupplements.length === 0}
                              onChange={(e) => {
                                  if (e.currentTarget.checked) {
                                      setSelectedSupplements([]);
                                  }
                              }}>
                    None
                </ToggleButton>
                {supplementButtons}
                <span className="flex-grow-1"/>
            </Stack>
            <Stack direction="horizontal" gap={3}>
                <Button className="w-50" variant="secondary" onClick={prev}>Previous</Button>
                <Button className="w-50" onClick={handleClick}>Next</Button>
            </Stack>
        </>
    )
}

export default Supplements;