import React from "react";
import {Navigate, Outlet} from "react-router-dom";
import {useUserAuth} from "../contexts/UserAuthContext";


const AdminRoutes = () => {
    const {user} = useUserAuth();

    if (!user || Object.keys(user).length === 0) {
        return <Navigate to="/"/>;
    }

    if (!user.isAdmin) {
        return <Navigate to="/error" state={{
            code: 403,
            message: 'You do not have access to the admin pages'
        }}/>
    }

    return (
        <div className="border border-3 border-danger">
            <Outlet/>
        </div>
    )
}

export default AdminRoutes;