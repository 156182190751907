import {useState} from 'react';
import {FloatingLabel, Form, InputGroup, Stack} from 'react-bootstrap';
import 'react-multi-carousel/lib/styles.css';
import Button from "react-bootstrap/Button";
import {useUserAuth} from "../../../contexts/UserAuthContext";

const Biometrics = ({prev, next}) => {

    const Weight = {Lbs: 'lbs', Kg: 'kg'}
    const Height = {FtIn: 'ft in', Cm: 'cm'}

    const {updateUserData, userData} = useUserAuth();
    const today = new Date();
    const [weightUnit, setWeightUnit] = useState(userData.weightUnit || Weight.Lbs);
    const [heightUnit, setHeightUnit] = useState(userData.heightUnit || Height.FtIn);
    const [dateOfBirth, setDateOfBirth] = useState(userData.dateOfBirth);
    const [weight, setWeight] = useState(userData.weight);
    const [height, setHeight] = useState(
        heightUnit === Height.FtIn ? Math.floor(userData.height) : userData.height);
    const [heightInches, setHeightInches] = useState(
        heightUnit === Height.FtIn ? Math.round(12 * (userData.height - Math.floor(userData.height))) : null
    );

    function handleClick() {
        if (!updateUserData("dateOfBirth", dateOfBirth, "weight", weight, "weightUnit", weightUnit,
            "height", heightUnit === Height.Cm ? height : height + heightInches / 12, "heightUnit", heightUnit)) {
            return;
        }
        next();
    }

    return (
        <>
            <Stack className="justify-content-center" gap={3}>
                <h3>What are your Biometrics?</h3>
                <p>Please select your date of birth, weight and height for better personalized testing experience</p>
                <FloatingLabel label="Year of Birth (YYYY)">
                    <Form.Control type="number" min={today.getFullYear() - 100} max={today.getFullYear()} step={1}
                                  value={dateOfBirth} placeholder="Year of Birth (YYYY)"
                                  onChange={e => setDateOfBirth(e.currentTarget.value)}/>
                    {/*<Form.Control type="date" value={dateOfBirth}*/}
                    {/*              max={today.toISOString().split('T')[0]}*/}
                    {/*              min={new Date(today.getFullYear() - 100, today.getMonth(), today.getDay())*/}
                    {/*                  .toISOString().split('T')[0]}*/}
                    {/*              onChange={(e) => setDateOfBirth(e.currentTarget.value)}*/}
                    {/*/>*/}
                </FloatingLabel>
                <InputGroup>
                    <FloatingLabel label={"Weight (" + weightUnit + ")"}>
                        <Form.Control type="number" id="weight" name="weight" value={weight}
                                      onChange={(e) => setWeight(parseFloat(e.currentTarget.value))}/>
                    </FloatingLabel>
                    <InputGroup.Text style={{cursor: 'pointer'}} className="user-select-none"
                                     onClick={() => {
                                         setWeightUnit(weightUnit === Weight.Lbs ? Weight.Kg : Weight.Lbs)
                                     }}>
                        {weightUnit}
                    </InputGroup.Text>
                </InputGroup>
                <InputGroup>
                    <FloatingLabel label={"Height (" + (heightUnit === Height.FtIn ? "ft" : heightUnit) + ")"}>
                        <Form.Control type="number" id="height" value={height} onChange={
                            (e) => setHeight(parseInt(e.currentTarget.value))
                        }/>
                    </FloatingLabel>
                    {heightUnit === Height.FtIn &&
                        <FloatingLabel label="(in)">
                            <Form.Control type="number" id="height" value={heightInches} min="1" max="11"
                                          onChange={
                                              (e) => setHeightInches(
                                                  parseInt(e.currentTarget.value))
                                          }/>
                        </FloatingLabel>
                    }
                    <InputGroup.Text style={{cursor: 'pointer'}} className="user-select-none"
                                     onClick={() =>
                                         setHeightUnit(heightUnit === Height.FtIn ? Height.Cm : Height.FtIn)
                                     }>
                        {heightUnit}
                    </InputGroup.Text>
                </InputGroup>
            </Stack>
            <Stack direction="horizontal" gap={3}>
                <Button className="w-50" variant="secondary" onClick={prev}>Previous</Button>
                <Button className="w-50" onClick={handleClick} disabled={
                    dateOfBirth === undefined || dateOfBirth === null
                    || weight === undefined || Number.isNaN(weight)
                    || weightUnit === undefined || weightUnit === null
                    || height === undefined || Number.isNaN(height)
                    || heightUnit === undefined || heightUnit === null
                }>Next</Button>
            </Stack>
        </>
    )
}

export default Biometrics;