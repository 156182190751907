import React, {useEffect, useState} from 'react';
import Column from "../../components/Column";
import {useNavigate, useParams} from "react-router-dom";
import {useUserAuth} from "../../contexts/UserAuthContext";
import {getFileURL, getUserReport} from "../../firebase";
import Stack from "react-bootstrap/Stack";
import moment from "moment/moment";
import {captureException} from "@sentry/react";


const Report = () => {

    const {id} = useParams();
    const {user} = useUserAuth();
    const navigate = useNavigate();
    const [report, setReport] = React.useState<any>();
    const [fileURL, setFileURL] = useState<string>();

    if (!id || !user) {
        navigate("/account");
    }

    useEffect(() => {
        const path = `reports/${user.uid}/${user.uid}-${id}-mobile.png`;
        getFileURL(path)
            .then((url) => {
                setFileURL(url);
            })
            .catch((error) => {
                console.error(`Cannot display report ${id} for user ${user.uid}: ${error.message}`);
                captureException(`Cannot display report ${id} for user ${user.uid}: ${error.message}`);
                alert('Cannot display the report');
                navigate("/account");
            });

        getUserReport(user.uid, id)
            .then((data) => setReport(data));
    }, [user?.uid, id])

    return (
        <Column>
            <Stack className="gap-md-3 my-1 my-md-3" gap={1}>
                <h3 className="align-self-center">Report {report?.date && moment(report?.date.toDate()).format('YYYY-MM-DD')}</h3>
                <img src={fileURL} className="ratio ratio-1x1 mb-6" width="100%" alt="Loading your report..."/>
            </Stack>
        </Column>
    )
}

export default Report;