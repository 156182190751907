import {Outlet} from 'react-router-dom';
import {Container, Row} from "react-bootstrap";

const Layout = () => {
    return (
        <>
            <Container>
                <Row>
                    <Outlet/>
                </Row>
            </Container>
        </>
    )
}

export default Layout;