import React, {useEffect, useMemo, useState} from "react";
import Column from '../../components/Column';
import {useUserAuth} from "../../contexts/UserAuthContext";
import {ColumnDef} from "@tanstack/react-table";
import TableWithPages from "../../components/TableWithPages";
import moment from "moment";
import {getUserTests} from "../../firebase";
import {getTestStatus} from "../../utils/Utils";
import {Badge, Stack} from "react-bootstrap";


const Tests = () => {

    const [userTests, setUserTests] = useState<Array<any>>([]);
    const {user} = useUserAuth();

    useEffect(() => {
        getUserTests(user.uid)
            .then((tests) => setUserTests(tests));
    }, [user])

    const columns = useMemo<ColumnDef<any>[]>(() => [
        // {
        //     id: 'index',
        //     accessorFn: (row: any, i: number) => i + 1
        //     // cell: ({row}) => row.index + 1
        // },
        {
            accessorKey: 'barcode',
            cell: info => info.getValue(),
            header: () => <span>Barcode</span>,
            footer: props => props.column.id
        },
        {
            accessorKey: 'testingKitId',
            header: 'Kit'
        },
        {
            accessorFn: ({date}) => moment(date.toDate()).format('YYYY-MM-DD'),
            header: 'Date'
        },
        {
            accessorFn: (test) => getTestStatus(test),
            header: 'Status',
            cell: (props: {getValue: () => any}) => <Badge className="small">{props.getValue()}</Badge>
        }
    ], [])

    return (
        <Column>
            <Stack className="gap-md-3 my-1 my-md-3" gap={1}>
                <h3 className="align-self-center">Your tests:</h3>
                <TableWithPages data={userTests} columns={columns} initialSorting={{id: 'Date', desc: true}}
                                />
            </Stack>
        </Column>
    )
}

export default Tests;