import {Stack, ToggleButton} from 'react-bootstrap';
import 'react-multi-carousel/lib/styles.css';
import Button from "react-bootstrap/Button";
import {useUserAuth} from "../../../contexts/UserAuthContext";
import {useState} from "react";

const AlcoholConsumption = ({prev, next, barcode}) => {
    const {updateScanData, getLastTestExcept} = useUserAuth();
    const previousTest = getLastTestExcept(barcode);
    const alcoholConsumptionAnswers = ["Never", "Rarely (less than once a month)",
        "Occasionally (1-3 times a month)", "Frequently (1-3 times a week)", "Daily"];
    const [selectedAlcoholConsumption, setSelectedAlcoholConsumption] = useState(previousTest?.alcoholConsumption);

    function handleClick() {
        updateScanData(barcode, "alcoholConsumption", selectedAlcoholConsumption)
            .then(() => next());
    }

    return (
        <>
            <Stack className="overflow-y-auto" gap={3}>
                <h3 className="flex-grow-1">Alcohol Consumption</h3>
                <p>How frequently did you consume alcoholic beverages in the past three months?</p>
                {
                    alcoholConsumptionAnswers.map((consumption) =>
                        <ToggleButton id={consumption.replace(' ', '-').toLowerCase()}
                                      key={consumption.replace(' ', '-').toLowerCase()}
                                      value={consumption} type="radio" className="rounded-5" variant="outline-dark"
                                      checked={selectedAlcoholConsumption === consumption}
                                      onChange={() => setSelectedAlcoholConsumption(consumption)}>
                            {consumption}
                        </ToggleButton>
                    )
                }
                <span className="flex-grow-1"/>
            </Stack>
            <Stack direction="horizontal" gap={3}>
                <Button className="w-50" variant="secondary" onClick={prev}>Previous</Button>
                <Button className="w-50" onClick={handleClick} disabled={!selectedAlcoholConsumption}>Next</Button>
            </Stack>
        </>
    )
}

export default AlcoholConsumption;