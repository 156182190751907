import React, {useState} from 'react';
import {Form, InputGroup, Stack} from "react-bootstrap";
import Button from "react-bootstrap/Button";

const Name = (props: {
    next: (firstName: string, lastName: string) => void,
}) => {

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");

    return (
        <>
            <Stack gap={1} className="overflow-y-auto" style={{height: '100%'}}>
                <h3 className="flex-grow-1">Your Name</h3>
                <p>Please, input your name</p>
                <InputGroup>
                    <Form.Control type="text" name="firstName" value={firstName}
                                  onChange={(e) => setFirstName(e.target.value?.trim())}
                                  placeholder="First name"/>
                </InputGroup>
                <InputGroup>
                    <Form.Control type="text" name="lastName" value={lastName}
                                  onChange={(e) => setLastName(e.target.value?.trim())}
                                  placeholder="Last name"/>
                </InputGroup>

            </Stack>
            <Stack direction="horizontal" gap={3}>
                <Button className="ms-auto w-50" onClick={() => props.next(firstName, lastName)}
                        disabled={!firstName || !lastName}>Next</Button>
            </Stack>
        </>
    )
}

export default Name;