import React from "react"
import {useUserAuth} from "../../../contexts/UserAuthContext";
import {Button, Stack, Table} from "react-bootstrap";
import {Link} from "react-router-dom";

const Admin = () => {

    const {countDocuments} = useUserAuth();

    const [numBarcodes, setNumBarcodes] = React.useState<number>();
    const [numUsers, setNumUsers] = React.useState<number>();

    countDocuments('barcodes')
        .then((count: number) => setNumBarcodes(count));

    countDocuments('users')
        .then((count: number) => setNumUsers(count));

    return (
        <Stack className="m-1 gap-md-3 m-md-3" gap={1}>
            <h5>Statistics</h5>
            <Table className="small table-sm">
                <tbody>
                <tr>
                    <th>Barcodes</th>
                    <td>{numBarcodes}</td>
                </tr>
                <tr>
                    <th>Users</th>
                    <td>{numUsers}</td>
                </tr>
                </tbody>
            </Table>
            <h5>Actions</h5>
            <Link to="/account/admin/scan"><Button>Test Barcode scanning</Button></Link>
            {/*/barcode*/}
            {/*<Link to="/account/admin/scan/qrcode"><Button>Test QRCode scanning</Button></Link>*/}
        </Stack>
    )
}

export default Admin;