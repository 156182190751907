import React from "react";
import Scan from "../test/Scan";
import {useNavigate} from "react-router-dom";
import Column from "../../../components/Column";

const TestScan = () => {  // {scanType}: {scanType: string}
    const navigate = useNavigate();
    // const [barcode, setBarcode] = React.useState<string>('');

    const next = () => {
        navigate("/account/admin");
    }

    const setBarcodeWithAlert = (barcode: string, onSuccess: any) => {
        alert(`Read barcode value: ${barcode}`);
        // setBarcode(barcode);
        onSuccess();
    }

    return (
        <Column>
            <Scan next={next} barcode={''} setBarcode={setBarcodeWithAlert}/>
            {/*scanType={scanType}*/}
        </Column>
    )
}

export default TestScan;