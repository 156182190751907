import React from 'react';
import Column from "../components/Column";
import {Stack} from "react-bootstrap";
import {Link, useLocation, useSearchParams} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope} from "@fortawesome/free-solid-svg-icons";
import {captureException} from "@sentry/react";
import Button from "react-bootstrap/Button";

const Error = () => {
    const location = useLocation();
    const error = location.state.error;
    const code = location.state.code;
    const message = location.state.message;

    return (
        <Column>
            <Stack className="d-flex justify-content-center text-center vh-custom" gap={3}>
                <h1>Something went wrong!</h1>
                <p className="text-danger">{code}: {message ?? String(error)}</p>
                <Stack direction="horizontal" className="justify-content-center" gap={3}>
                    <Link to="/"><Button>Home</Button></Link>
                    <Link to={"mailto:support@arome-science.com"}>
                        <Button variant="secondary">
                            <FontAwesomeIcon icon={faEnvelope} size={"1x"}/>&nbsp;Contact support
                        </Button>
                    </Link>
                </Stack>
            </Stack>
        </Column>
    )
}

export default Error;