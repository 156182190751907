import {Stack, ToggleButton} from 'react-bootstrap';
import 'react-multi-carousel/lib/styles.css';
import Button from "react-bootstrap/Button";
import {useUserAuth} from "../../../contexts/UserAuthContext";
import {useState} from "react";

const AntibioticUse = ({prev, next, barcode}) => {
    const {updateScanData, getLastTestExcept} = useUserAuth();
    const previousTest = getLastTestExcept(barcode);
    const antibioticUseAnswers = ["Yes, within the past month", "Yes, within the past 3 months",
        "No, not in the past 3 months"];
    const [selectedAntibioticUse, setSelectedAntibioticUse] = useState(previousTest?.antibioticUse);

    function handleClick() {
        updateScanData(barcode, "antibioticUse", selectedAntibioticUse)
            .then(() => next());
    }

    return (
        <>
            <Stack className="overflow-y-auto" gap={3}>
                <h3 className="flex-grow-1">Antibiotic Use</h3>
                <p>Have you taken any antibiotics in the past three months?</p>
                {
                    antibioticUseAnswers.map((consumption) =>
                        <ToggleButton id={consumption.replace(' ', '-').toLowerCase()}
                                      key={consumption.replace(' ', '-').toLowerCase()}
                                      value={consumption} type="radio" className="rounded-5" variant="outline-dark"
                                      checked={selectedAntibioticUse === consumption}
                                      onChange={() => setSelectedAntibioticUse(consumption)}>
                            {consumption}
                        </ToggleButton>
                    )
                }
                <span className="flex-grow-1"/>
            </Stack>
            <Stack direction="horizontal" gap={3}>
                <Button className="w-50" variant="secondary" onClick={prev}>Previous</Button>
                <Button className="w-50" onClick={handleClick} disabled={!selectedAntibioticUse}>Next</Button>
            </Stack>
        </>
    )
}

export default AntibioticUse;