import React from 'react';
import Column from "../components/Column";
import {Stack} from "react-bootstrap";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope} from "@fortawesome/free-solid-svg-icons";
import Button from "react-bootstrap/Button";

const PageNotFound = () => {
    return (
        <Column>
            <Stack className="d-flex justify-content-center text-center vh-custom" gap={3}>
                <h1>404 Error</h1>
                <h3>Page Not Found!</h3>
                <Stack direction="horizontal" className="justify-content-center" gap={3}>
                    <Link to="/"><Button>Home</Button></Link>
                    <Link to={"mailto:support@arome-science.com"}>
                        <Button variant="secondary">
                            <FontAwesomeIcon icon={faEnvelope} size={"1x"}/>&nbsp;Contact support
                        </Button>
                    </Link>
                </Stack>
            </Stack>
        </Column>
    )
}

export default PageNotFound;