import React from 'react';
import {ProgressBar, Stack} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import Column from "../../../components/Column";
import Scan from "./Scan";  // , {ScanType}
import PackAndShip from "./PackAndShip";
import ThankYou from "./ThankYou";
import BristolChart from "./BristolChart";
import {useUserAuth} from "../../../contexts/UserAuthContext";
import {DuplicationError, NotFoundError} from "../../../components/SWipeAppErrors";
import Supplements from "./Supplements";
import AlcoholConsumption from "./AlcoholConsumption";
import AntibioticUse from "./AntibioticUse";
import Intro from "./Intro";
// import ScanSelector from "./ScanSelector";


const Details = () => {

    const {verifyBarcode, updateScanData} = useUserAuth();
    // const [scanType, setScanType] = React.useState(ScanType.Barcode.toString());
    const [barcode, setBarcode] = React.useState(null);
    const [activeStep, setActiveStep] = React.useState(0);
    const navigate = useNavigate();
    let confirmation = null;

    function verifyAndSetBarcode(barcode, onSuccess) {
        verifyBarcode(barcode)
            .then(() => {
                setBarcode(barcode);
                onSuccess();
            })
            .catch((error) => {
                setBarcode(null);
                if (error instanceof NotFoundError) {
                    console.warn(error.message);
                } else if (error instanceof DuplicationError) {
                    if (confirmation === null) {
                        confirmation = window.confirm(
                            error.message + ' Do you want to overwrite the previous data?');
                    }
                    if (confirmation) {
                        setBarcode(barcode);
                        onSuccess();
                    } else {
                        navigate('/account');
                    }
                }
            });
    }

    function next() {
        setActiveStep(activeStep + 1);
    }

    function prev() {
        setActiveStep(activeStep - 1);
    }

    const steps = [
        <Intro next={next}/>,
        // <ScanSelector next={next} scanType={scanType} setScanType={setScanType}/>,
        <Scan next={() => {
            updateScanData(barcode, "barcode", barcode, "date", new Date())
                .then(next)
                .catch((err) => {
                    console.error(err);
                    throw new Error(`Cannot update the test data: ${err.message}`, {cause: err});
                });
        }} barcode={barcode} setBarcode={verifyAndSetBarcode}/>,
        // scanType={scanType}
        <BristolChart prev={prev} next={next} barcode={barcode}/>,
        <Supplements prev={prev} next={next} barcode={barcode}/>,
        <AlcoholConsumption prev={prev} next={next} barcode={barcode}/>,
        <AntibioticUse prev={prev} next={next} barcode={barcode}/>,
        <PackAndShip prev={prev} next={next}/>,
        <ThankYou/>
    ];

    function getSectionComponent() {
        if (activeStep < 0 || activeStep >= steps.length) {
            setActiveStep(0)
        }
        return steps[activeStep];
    }

    return (
        <>
            <ProgressBar now={100 * (1 + activeStep) / steps.length} variant="secondary" style={{height: '5px'}}/>
            <Column>
                <Stack className="d-flex text-center vh-custom p-1 gap-md-3 p-md-3" gap={1}>
                    {getSectionComponent()}
                </Stack>
            </Column>
        </>
    )
}

export default Details;