import {initializeApp} from 'firebase/app';
import {getAnalytics} from 'firebase/analytics';
import {getAuth, isSignInWithEmailLink, signInWithEmailLink, updatePassword} from 'firebase/auth';
import {getFirestore, collection, getDocs, query, where, getDoc, doc} from 'firebase/firestore';
import {getBytes, getDownloadURL, getStorage, ref} from 'firebase/storage';
import report from "./pages/account/Report";
import User from "./models/User";

export function mapAuthCodeToMessage(authCode) {
    switch (authCode) {
        case 'auth/email-already-in-use':
            return 'Provided email already in use.'
        case 'auth/invalid-credential':
            return 'Invalid password.'
        case 'auth/too-many-requests':
            return 'Too many requests. Please try again later.'
        case 'auth/weak-password':
            return 'Password should be at least 6 characters.'
        case 'auth/network-request-failed':
            return 'Network connection has failed. Please try again.'
        case 'auth/invalid-action-code':
            return 'You cannot use this authentication method anymore.'
        case 'auth/invalid-email':
            return 'Invalid email address.'
        default:
            return undefined;
    }
}

const firebaseConfig = {
    apiKey: "AIzaSyBzQu-bk3uNzgEFK6LtKMb7MI2jxGOKuWs",
    authDomain: "arome-s-wipe-app.firebaseapp.com",
    projectId: "arome-s-wipe-app",
    storageBucket: "arome-s-wipe-app.appspot.com",
    messagingSenderId: "164180344810",
    appId: "1:164180344810:web:1d6565e73d3fae0786bc93",
    measurementId: "G-3Q1MGZ8XQ7"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const users = collection(db, 'users');
export default app;


// export async function getUserReports(uid) {
//     const reportsRef = collection(db, "users", uid, 'reports');
//     return getDocs(reportsRef)
//         .then((querySnapshot) => {
//                 const reports = [];
//                 querySnapshot.forEach((report) => {
//                     reports.push(report.data());
//                 });
//                 return reports;
//             }
//         )
//         .catch((err) => {
//             console.error(err);
//             throw new Error(`Cannot access reports for user ${uid}`, {cause: err});
//         });
// }

export async function getUser(uid) {
    const userRef = doc(db, "users", uid);
    const userSnapshot = await getDoc(userRef);
    if (!userSnapshot.exists) {
        return null;
    }

    const userData = userSnapshot.data();
    return new User(uid, userData);
}

export async function getUserDocuments(uid, collectionName, condition) {
    let ref = collection(db, 'users', uid, collectionName);
    if (!!condition) {
        ref = query(ref, condition);
    }
    return getDocs(ref)
        .then((querySnapshot) => {
            const list = [];
            querySnapshot.forEach((snapshot) => {
                list.push(snapshot.data());
            });
            return list;
        })
        .catch((err) => {
            console.error(err);
            throw new Error(`Cannot access ${collectionName} for user ${uid}`, {cause: err});
        })
}


export async function getUserReports(uid) {
    const reports = await getUserDocuments(uid, "reports",
        where('emailed', "==", true));
    for (const report of reports) {
        const storageReference = ref(storage, report.file);
        report.downloadURL = await getDownloadURL(storageReference);
    }
    return reports;
}

export async function getUserReport(uid, reportId) {
    const reportSnapshot = await getDoc(doc(db, "users", uid, "reports", reportId));
    if (!reportSnapshot.exists())
        return null;

    return reportSnapshot.data();
}

export async function getFileBinary(path){
    const fileReference = ref(storage, path);
    return await getBytes(fileReference);
}

export async function getFileURL(path){
    const fileReference = ref(storage, path);
    return await getDownloadURL(fileReference);
}

export async function getUserTests(uid){
    return getUserDocuments(uid, "tests");
}

export async function completeSignInWithEmailLink(email, link, password) {
    if (!email || !isSignInWithEmailLink(auth, link)) return null;
    return signInWithEmailLink(auth, email, link)
        .then((userCredentials) => {
            const user = userCredentials.user;
            return updatePassword(user, password)
                .then(() => console.log(`Set new password for user ${user.uid}`));
        });
}

