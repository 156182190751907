import React, {useEffect} from 'react';
import {Html5Qrcode, Html5QrcodeScannerState, Html5QrcodeScanType, Html5QrcodeSupportedFormats} from "html5-qrcode";
import "react-barcode-scanner/polyfill"
import Button from "react-bootstrap/Button";
import {Form, Stack} from "react-bootstrap";
import {Link} from "react-router-dom";
import {faCameraRotate} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {captureException} from '@sentry/react';
import {useUserAuth} from "../../../contexts/UserAuthContext";


const Scan = ({next, barcode, setBarcode}) => {

    const {updateScanData} = useUserAuth();
    const [showSuccessMessage, setShowSuccessMessage] = React.useState(false);
    // const [cameraIndex, setCameraIndex] = React.useState(null);
    const [cameras, setCameras] = React.useState(null);
    const [cameraIndex, setCameraIndex] = React.useState(0);
    const [barcodeText, setBarcodeText] = React.useState('');
    // const camerasRef = React.useRef(null);
    const html5QrCodeRef = React.useRef(null);

    useEffect(() => {
        Html5Qrcode.getCameras().then(
            devices => {
                console.log(`Found ${devices ? devices.length : 0} cameras`);
                if (devices && devices.length > 0) {
                    setCameras(devices);
                    try {
                        for (let i = 0; i < devices.length; i++) {
                            const camera = devices[i];
                            if (camera.label?.toLowerCase().includes("back")) {
                                setCameraIndex(i);
                            }
                        }
                    } catch (err) {
                        console.error(err);
                        captureException(err);
                    }
                }
            })
            .catch((error) => {
                console.error('Error when getting cameras', error.message);
                if (error.name === 'NotAllowedError' || error.name === 'AbortError') {
                    alert('Please refresh this page and allow camera access. Alternatively, you can manually enter the ' +
                        'barcode number below.');
                } else {
                    throw error;
                }
            })

        html5QrCodeRef.current = new Html5Qrcode("reader", {
            formatsToSupport: [Html5QrcodeSupportedFormats.CODE_128],
            verbose: false
        });

        return () => {
            if (html5QrCodeRef.current !== null
                && html5QrCodeRef.current.getState() === Html5QrcodeScannerState.SCANNING) {
                html5QrCodeRef.current.stop()
                    .catch((error) => {
                        console.error('Error when stopping Html5QrCode: ' + error);
                    });
            }
        }
    }, []);

    useEffect(() => {

        async function start() {
            if (cameraIndex === null || cameras === null || html5QrCodeRef.current === null) return;

            try {
                if (html5QrCodeRef.current.getState() === Html5QrcodeScannerState.SCANNING) {
                    await html5QrCodeRef.current.stop();
                }
            } catch (error) {
                console.error('Error when stopping Html5QrCode', error.message);
                throw new Error(error);
            }

            const configuration = {
                fps: 10,
                qrbox: (width, height) => {
                    if (!width || !height) return 250;
                    return {
                        width: 0.8 * width,
                        height: 0.8 * height
                    }
                },
                facingMode: "environment",
                aspectRatio: 1.0,
                height: {min: 400, ideal: 720},
                width: {min: 400, ideal: 720},
                focusMode: "continuous",
                focusDistance: 0.0254,
                formatsToSupport: [
                    Html5QrcodeSupportedFormats.CODE_128,
                    Html5QrcodeSupportedFormats.QR_CODE
                ],
                supportedScanTypes: [Html5QrcodeScanType.SCAN_TYPE_CAMERA],
                advanced: [{zoom: 1.0}],
                experimentalFeatures: {useBarCodeDetectorIfSupported: false}
            };

            console.log('Starting camera...', cameraIndex, cameras[cameraIndex].id);
            html5QrCodeRef.current.start(cameras[cameraIndex].id,
                configuration,
                (decodedText, decodedResult) => {
                    if (decodedText !== null) {
                        setBarcode(decodedText, () => {
                            setShowSuccessMessage(true);
                            setBarcodeText(decodedText);
                        });
                    }
                },
                (errorMessage, error) => {
                    if (error.type !== 0) {
                        console.error('Error while scanning: ' + error);
                        captureException(error);
                        alert('Barcode scanning was interrupted. Please refresh this page and scan again.')
                    }
                }
            ).catch((error) => {
                console.error('Error in html5qrcode.start: ' + error)
                captureException(error);
                alert('Cannot start the camera. Try to reset camera permissions and make sure that no other app is ' +
                    'using the camera at the moment. If this does not help, you can manually enter the barcode number ' +
                    'below');
            });
        }

        start().catch((error) => {
            console.error('Error in async start: ' + error)
            captureException(error);
        });

    }, [cameraIndex, cameras])

    // function handleScan(barcode) {
    //     if (barcode !== null) {
    //         setBarcode(barcode, () => setShowSuccessMessage(true));
    //     }
    // }

    function handleClick() {
        try {
            updateScanData(barcode, "barcode", barcode, "date", new Date());
            next();
            // if (html5QrCode !== null) {
            //     html5QrCode.stop();
            // }
        } catch (error) {
            console.error(error);
        }
    }

    function changeCamera() {
        if (cameras === null) return;

        if (cameraIndex === null) {
            setCameraIndex(0);
        } else {
            setCameraIndex((cameraIndex + 1) % cameras.length);
        }
    }

    return (
        <>
            <h3 className="flex-grow-1">Scan Barcode on S-Wipe tube</h3>
            <Stack className="justify-content-center flex-grow-1 gap-md-3" gap={1}>
                <div>
                    <div className="position-relative">
                        <div id="reader" style={{width: '100%'}}></div>
                        <div className="position-absolute top-0 px-1 text-center text-white small"
                             style={{width:'100%'}}>
                            {cameras !== null && cameraIndex !== null ? cameras[cameraIndex].label : ''}
                        </div>
                        <div className="position-absolute bottom-0 p-1 d-flex justify-content-center w-100">
                            <Button className="py-0 px-3 border-2 rounded-0" variant="outline-light"
                                    onClick={() => changeCamera()}>
                                <FontAwesomeIcon icon={faCameraRotate} className="me-1"/>
                                Change camera
                            </Button>
                        </div>
                    </div>
                </div>
                {/*<p>Click the button <FontAwesomeIcon icon={faCameraRotate}/> to change the camera</p>*/}
                <h6 hidden={!showSuccessMessage}>
                    Barcode scanned successfully. Press <Link onClick={handleClick}>next</Link> to continue.
                </h6>
            </Stack>
            <div className="flex-grow-1">
                <Stack direction="horizontal" gap={1}>
                    <Form.Label className="small">Scan or enter the barcode manually</Form.Label>
                    <Form.Control type="number" value={barcodeText ?? ''}
                                  onChange={(e) => {
                                      setBarcodeText(e.currentTarget.value);
                                      setBarcode(e.currentTarget.value, () => setShowSuccessMessage(true));
                                  }}/>
                </Stack>
            </div>
            <Stack direction="horizontal" gap={3}>
                <Button className="ms-auto w-50" onClick={handleClick}
                        disabled={barcode === null}>Next</Button>
            </Stack>
        </>
    )
}

export default Scan;